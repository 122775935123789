import React from 'react';

const FullPage = ({props, children}) => {
    return(
        <React.Fragment>
            <div 
                className="w-full h-screen overflow-hidden"
                {...props}
            >
                {children}
            </div>
        </React.Fragment>
    )
}

export default FullPage;